<template>
  <v-card class="profile-info-container pilot-informations">
    <v-toolbar class="profile-info-toolbar">
      <v-btn @click.prevent="$router.go(-1)" class="btn-back">
        <v-icon>mdi-chevron-left</v-icon>
        <span class="ml-2">Back</span>
      </v-btn>
    </v-toolbar>
    <v-card class="profile-info-card">
      <div class="font-20 fw-500 text-left">
        Contacts List
      </div>

      <!-- Search Component -->
      <v-text-field
        v-model="searchBase"
        placeholder="Base Name / Contact Name / Crew Type"
        prepend-inner-icon="mdi-magnify"
        color="primary"
        hide-details
        class="select-input-logbook" />

      <div class="responsive-base-table">
        <v-data-table
          :headers="contactHeaders"
          :items="contactData"
          :items-per-page="20"
          :expanded.sync="expanded"
          sort-by="name"
          :search="searchBase"
          hide-default-footer
          item-key="id"
          show-expand
          fixed-header
          mobile-breakpoint="0"
          :page.sync="contactPage"
          @page-count="pageCount = $event"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="text-center py-3">
              <div v-if="$ReformatToArray(item.informations).length">
                <div v-for="(data, index) in $ReformatToArray(item.informations)" :key="index" class="contact-container" @click.prevent="OpenURL(`https://wa.me/${$formatPhone(data.phone)}`)">
                  <div class="contact-name">
                    {{ data.name }}
                  </div>
                  <div class="contact-position">
                    ({{ data.title }})
                  </div>
                  <div class="contact-number">
                    <span>
                      {{ $formatPhone(data.phone) }}
                    </span>
                    <v-icon left small color="white">
                      mdi-whatsapp
                    </v-icon>
                  </div>
                </div>
              </div>
              <div v-else class="py-3 text-center">
                <img src="@/assets/illustrations/search-contact.svg" loading="lazy" alt="No Contact" width="25%">
                <div class="font-13 fw-500 mt-3">
                  No Contacts
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
        <div v-if="pageCount > 1" class="text-center pt-2">
          <v-pagination
            v-model="contactPage"
            :length="pageCount"
          ></v-pagination>
        </div>
      </div>

    </v-card>
  </v-card>
</template>
<script>
import { getMessaging, onMessage } from 'firebase/messaging'

export default {
  name: 'Contact',
  data: (vm) => ({
    searchBase: '',
    expanded: [],
    contactHeaders: [
      { text: 'Base Name', align: 'start', class: 'primary--text font-14', sortable: false, value: 'name' },
      { text: 'Area Name', align: 'start', class: 'primary--text font-14', sortable: false, value: 'area_name' },
      { text: '', class: 'd-none', value: 'informations' },
      { text: '', value: 'data-table-expand' }
    ],
    contactData: [],
    contactPage: 1,
    pageCount: 0
  }),
  mounted () {
    // Receive Notification from backend
    onMessage(getMessaging(), (payload) => {
      this.$toastNotif({
        message: payload.notification.body,
        type: 'info'
      })
    })
    this.loadBase()
  },
  methods: {
    async loadBase () {
      if (this.$store.state.base.all?.data?.data.length) {
        this.contactData = this.$store.state.base?.all?.data?.data
      } else {
        await this.$store.dispatch('GET_BASES', '?page=1&limit=1000&q=&status=1')
        this.contactData = this.$store.state.base?.all?.data?.data
      }
    },
    OpenURL (link) {
      this.$SweetAlert({
        title: 'Leaving Forpilots?',
        caption: 'You will be redirected into external web sites.',
        confirmText: 'Continue',
        onConfirm: () => { window.open(link, 'parent') }
      })
    }
  }
}
</script>
<style lang="scss">
@import '../assets/scss/global-vars';
@import '../assets/scss/fonts';

.pilot-informations{
  .select-input-logbook{
    &.v-input--is-focused{
      .v-input__slot::after{
        transform: scaleX(.966);
      }
    }
    .v-input__slot{
      border: 1px solid #F9F9F9;
      border-radius: 10px;
      padding: 0 16px;
      &::before{
        border-color: transparent !important;
      }
    }
    .v-input__prepend-inner{
      margin-top: 0;
      align-self: center;
      .v-icon{
        &::before{
          @include font-size(22px);
        }
      }
    }
    .v-text-field__slot,
    .v-select__slot{
      input{
        @include font-size(13px);
      }
      .v-input__append-inner{
        .v-icon::before{
          color: $danger;
          @include font-size(22px);
        }
        &:last-of-type{
          display: none;
        }
      }
    }
  }
  .responsive-base-table{
    margin-top: 20px;
    .v-data-table{
      background-color: #ededed;
      border-radius: 10px;
      thead > tr {
        th{
          background-color: #ededed;
        }
        >th:first-child{
          border-radius: 10px 0 0 0;
        }
        >th:last-child{
          border-radius: 0 10px 0 0;
        }
      }
      tbody > tr {
        >td:nth-child(3){
          display: none;
        }
      }
      .v-data-table__wrapper{
        height: calc(100vh - 400px);
        .contact-container{
          display: inline-block;
          vertical-align: middle;
          width: auto;
          text-align: left;
          background-color: #128C7E;
          color: $white;
          border-radius: 10px;
          padding: 6px 12px;
          margin: 0 8px 8px 0;
          .contact-name{
            display: inline;
            @include font-size(13px);
            vertical-align: middle;
          }
          .contact-position{
            display: inline;
            @include font-size(12px);
            font-weight: 500;
            vertical-align: middle;
            margin-left: 4px;
          }
          .contact-number{
            display: block;
            span{
              @include font-size(12px);
              margin-right: 2px;
            }
          }
        }
      }
    }
    .v-pagination__item{
      background-color: $white;
      border-radius: 10px;
      &:not(.v-pagination__item--active){
        box-shadow: none;
      }
    }
    .v-pagination__navigation{
      border-radius: 40px;
    }
  }
}
</style>
